*{
margin: 0;
padding: 0;
box-sizing: border-box;

}
body{
    background-color: var(--appColor);
    font-family: sans-serif;
}
.label{
    font-size: x-large;
    margin-left: 400px;

}
.table-container{

    padding: 0 10%;
    margin: 40px auto 0;
}

.container {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    color: var(--orange);
    font-weight: bold;
    font-size: 3em;
    margin-bottom: 20px;
  }
  p {
    color: #FFF;
  }  
.heading{
font-size: 40px;
color: white;
margin-bottom: 10px;
margin-top: 40px;
margin-left: 200px;
font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
font-style: italic; 
}
.table{
    width: 100%;
    border-collapse: collapse;
margin-bottom: 40px;
margin-top:50px ;


}
.table thead{
    background-color: var(--orange);
}
.table thead tr th{

    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.35px;
    color: white;
    opacity: 1;
    padding: 12px;
    vertical-align: top;
    border: 1px solid #dee2e685;
}
.table tbody tr td{
font-size: 14px;
letter-spacing: 0.35px;
font-weight: normal;
color: white;
background-color: #3c3f44;
padding: 8px;
text-align: center;
border: 1px solid #dee2e685;

}

#img1{
    width: 15rem;
  height: 20rem;
  margin-left: 400px;
  }
.sel{
    margin-left: 400px;
    margin-top: 20px;
}
.sentence{
    color:wheat;
    margin-left: 100px;
    text-decoration-style: wavy;
}








@media screen and (max-width: 768px) {
.table thead{
    display: none;
}
.table, .table tbody, .table tr, .table td {

    display: block;
    width: 100%;
}
.table tr{
    margin-bottom: 15px;
}
.table  tbody tr td{
    text-align: right;
    padding-left: 50%;
    position: relative;
}
.table td::before{
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
}

.heading{
    font-size:20px;
    display: block;
    margin-left: 80px;
    margin-bottom: 5px;
}
.sentence{
    font-size: 15px;
    position: relative;
}
    
}